import '../assets/css/profileedit.css';
import "../assets/css/contact.css"
import Formsy from 'formsy-react';
import { navigate, Link } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';


import Layout from '../components/layout';
// import LeftProfile from '../components/leftProfile';
import MyInput from '../components/MyInput';
import MyInputMask from '../components/MyInputMask';

import SEO from '../components/seo';
import { setCredentials } from './../core/auth';
import { isAuthenticated,getAuthenticatedUser } from '../core/util';
import closeChat from '../images/close-icon.svg';

import * as actionCreators from './../store/actions';


// import newslatter from "../images/newslatter.svg"
//import contactcloud from "../images/contacts/cloud.svg"
// import { Link } from "gatsby";
class SendClaimPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            userToken:null,
            profile:null,
            successMsg:null,
            errMsg:null,
            contractId:null,
        };
    }

    async componentDidMount() {
        if (!isAuthenticated()) {
            navigate("/"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
        if(this.props.location.state && this.props.location.state.contractId){
            this.setState({ contractId: this.props.location.state.contractId })
        } else {
           navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
        let usrdata = getAuthenticatedUser();
        this.setState({ userToken: usrdata.token })
        await this.getFromDirect(usrdata.token);
    }

    getFromDirect = async(paramId) => {
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getProfile'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: paramId,
                })
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    this.setState({ profile: res.me })
                } else {
                    setCredentials({});
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
            });
    }
    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = async(model) => {
        const { showLoader, hideLoader } = this.props;
        showLoader();
        model.token = this.state.userToken;
        model.contractId = this.state.contractId;
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/sendClaim'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(model)
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    this.setState({ successMsg: 'Your Claim Sent Successfully',errMsg: '' })
                    this.refs['claimForm'].reset();
                } else if(res.status === 0){
                        this.setState({ errMsg: res.msg, successMsg: '' })
                } else if(res.status === -1) {
                    setCredentials({});
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
                
                hideLoader();
            });

        
    }

    render() {
        const { canSubmit, profile,successMsg,contractId,errMsg } = this.state;
        if(!profile) return '';
        return (
            <Layout layoutType="profileHeader">
                <SEO title="Servicecontract.com" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                
                <section className={global.dealertoken?"formpart grayish":"formpart"}>
                    <div className="container">
                        
                        <div className="contact-form">
                            <Formsy ref="claimForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                {/* <ErrrorHandel formName={'claimForm'} self={this} /> */}
                                <div className="left-side">
                                    <h1>Claim <img onClick={() => navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))} className="userclose" src={closeChat} alt="" /></h1>
                                    <h5>{'Contract ID : '+ contractId}</h5>
                                    <div className="input-type">
                                        <MyInput
                                            name="title"
                                            id="title"
                                            placeholder="Title"
                                            type="text"
                                            value=""
                                            isLabelRequired={false}
                                            className="form-control"
                                            required
                                        />
                                        <MyInput
                                            name="mileage"
                                            id="mileage"
                                            placeholder="Current Mileage"
                                            className="form-control"
                                            value=""
                                            isLabelRequired={false}
                                            validations="isNumeric"
                                            validationError="Please enter a valid number."
                                            required
                                        />
                                        <MyInput
                                            name="message"
                                            id="textarea"
                                            placeholder="Tell us what happened"
                                            type="text"
                                            value=""
                                            validations={{
                                                minLength: 10
                                            }}
                                            isLabelRequired={false}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="success-txt">{successMsg}</div>
                                    <div className="error-txt">{errMsg}</div>
                                    <div className="button-container change-pass-container-btn">
                                        <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled change-pass-btn' : 'btn change-pass-btn'}>
                                            Send
                                        </button>
                                        <Link className="btn change-pass-btn outline" to={"/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:'')}>Back</Link>
                                    </div>
                                </div>
                            </Formsy>
                            <div className="right-side">
                                
                            </div>
                        </div>
                    </div>
                </section>

                <section className="copyright-container last-text">
                    <div className="container">
                        <div className="copyright">
                            <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
                        </div>
                    </div>

                </section>

            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        showSuccessPopup: (payload) => dispatch(actionCreators.showSuccessPopup(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SendClaimPage);

